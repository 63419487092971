<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">จัดการสินค้า</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <!-- ProductListCard -->
      <div class="row">
        <div class="col-12">
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">สินค้า</h5>
                </div>
              </div>
            </template>
            <div class="row justify-content-end">
              <div class="form-group mr-2">
                <select
                  class="form-control"
                  v-model="branch"
                  @change="fetchProduct"
                >
                  <option value="all">ทั้งหมด</option>
                  <option value="loei">อ.เมือง</option>
                  <option value="wang">อ.วังสะพุง</option>
                  <option value="ck">อ.เชียงคาน</option>
                </select>
              </div>
              <div class="form-group">
                <input
                  type="search"
                  v-model="productData.searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="productData.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in productData.pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="productData.pagination.currentPage"
                  :per-page="productData.pagination.perPage"
                  :total="totalProduct"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="productData.loading"
                :data="queriedDataProduct"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="180px"
                  align="center"
                  label="ชื่อสินค้า"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150px"
                  align="center"
                  label="หมวดหมู่"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.category }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120px" align="center" label="ราคา">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.price }} บาท
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="ลดราคา"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.discount }} บาท
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="120px"
                  align="center"
                  label="ราคาสุทธิ"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.price - props.row.discount }} บาท
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="120px" label="สินค้าคงเหลือ">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      <div class="d-flex justify-content-between">
                        <span>อ.เมือง</span
                        ><span>{{ props.row.loei_stock }}</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>อ.วังสะพุง</span
                        ><span>{{ props.row.wang_stock }}</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <span>อ.เชียงคาน</span
                        ><span>{{ props.row.ck_stock }}</span>
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px" align="center" label="รูป">
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      <img
                        v-if="props.row.img"
                        :src="imagePath + props.row.img"
                        :alt="props.row.name"
                        class="rounded"
                        style="max-height: 70px; width: auto"
                      />
                    </div>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150px"
                  align="center"
                  label="แก้ไข / ลบ"
                >
                  <template v-slot:default="props">
                    <button
                      class="btn base-button btn-sm btn-primary rounded-circle"
                      @click="fetchEditProductData(props.row.id)"
                    >
                      <i class="fa fa-edit"></i>
                    </button>
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeleteProduct(props.row.name)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div>
                  <p>
                    แสดง {{ fromProduct + 1 }} ถึง {{ toProduct }} จากทั้งหมด
                    {{ totalProduct }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
        </div>
      </div>
      <!-- ProductListCard -->
      <div class="row">
        <div class="col-xl-6">
          <!-- CategoryCard -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">จัดการหมวดหมู่</h5>
                </div>
              </div>
              <div class="row w-100 d-flex justify-content-center mt-3">
                <input
                  type="text"
                  class="form-control w-50"
                  placeholder="เพิ่มหมวดหมู่"
                  v-model="categoryCard.inputCategory"
                />
                <button
                  class="btn btn-base btn-primary"
                  @click="onSubmitAddCategory"
                >
                  เพิ่ม
                </button>
              </div>
            </template>
            <div class="row justify-content-end">
              <div class="form-group">
                <input
                  type="search"
                  v-model="categoryCard.category.searchQuery"
                  id="search"
                  class="form-control"
                  placeholder="ค้นหา"
                />
              </div>
            </div>
            <div class="row">
              <div
                class="col-md-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="form-group">
                  <label for="selectshow" class="form-control-label"
                    >แสดง</label
                  >
                  <el-select
                    class="select-primary pagination-select mx-2"
                    v-model="categoryCard.category.pagination.perPage"
                    placeholder="Per page"
                    style="width: 4.5rem"
                    id="selectshow"
                  >
                    <el-option
                      class="select-primary"
                      v-for="item in categoryCard.category.pagination
                        .perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    >
                    </el-option>
                  </el-select>
                  <label for="selectshow" class="form-control-label"
                    >รายการ</label
                  >
                </div>
                <base-pagination
                  class="pagination-no-border"
                  v-model="categoryCard.category.pagination.currentPage"
                  :per-page="categoryCard.category.pagination.perPage"
                  :total="totalCategory"
                >
                </base-pagination>
              </div>
            </div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-table
                v-loading="categoryCard.category.loading"
                :data="queriedDataCategory"
                header-row-class-name="thead-light"
              >
                <el-table-column
                  min-width="150px"
                  align="center"
                  label="ชื่อหมวดหมู่"
                >
                  <template v-slot:default="props">
                    <div class="font-weight-bold">
                      {{ props.row.name }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px" align="center" label="ลิงก์">
                  <template v-slot:default="props">
                    <button type="button" class="btn btn-secondary btn-sm" @click="copyCategoryUrl(props.row.name)">คัดลอกลิงก์</button>
                  </template>
                </el-table-column>
                <el-table-column
                  min-width="150px"
                  align="center"
                  label="แก้ไข / ลบ"
                >
                  <template v-slot:default="props">
                    <base-button
                      round
                      size="sm"
                      type="primary"
                      @click="
                        categoryCard.category.editModal = true;
                        categoryCard.category.editName = props.row.name;
                      "
                    >
                      <i class="fa fa-edit"></i>
                    </base-button>
                    <button
                      class="btn base-button btn-sm btn-danger rounded-circle"
                      @click="onDeleteCategory(props.row.name)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <template v-slot:footer>
              <div
                class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
              >
                <div class="">
                  <p class="card-category">
                    แสดง {{ fromCategory + 1 }} ถึง {{ toCategory }} จากทั้งหมด
                    {{ totalCategory }} รายการ
                  </p>
                </div>
              </div>
            </template>
          </card>
          <!-- CategoryCard -->
        </div>
        <div class="col-xl-6">
          <!-- ProductAddMenu -->
          <card header-classes="bg-transparent">
            <template v-slot:header>
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0">เพิ่มสินค้า</h5>
                </div>
              </div>
              <div class="row w-100 d-flex justify-content-center mt-3">
                <div class="row w-100">
                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ชื่อสินค้า"
                      v-model="productAddData.input.name"
                    />
                  </div>
                  <div class="col-6">
                    <select
                      class="form-control"
                      v-model="productAddData.input.category"
                    >
                      <option value="" disabled>--เลือกหมวดหมู่--</option>
                      <option
                        v-for="(item, index) in categoryCard.category.tableData"
                        :key="index"
                        :value="item.name"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="row w-100 mt-3">
                  <div class="col-12">
                    <textarea
                      class="form-control"
                      rows="3"
                      placeholder="รายละเอียด"
                      v-model="productAddData.input.description"
                    ></textarea>
                  </div>
                </div>
                <div class="row w-100 mt-3">
                  <div class="col-6">
                    <label class="form-control-label">ราคา</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="productAddData.input.price"
                    />
                  </div>
                  <div class="col-6">
                    <label class="form-control-label">ส่วนลด</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="productAddData.input.discount"
                    />
                  </div>
                </div>
                <div class="row mt-3 w-100 border-bottom pb-2">
                  <div class="w-100 d-flex justify-content-center">
                    <label class="font-weight-bold"
                      >จำนวนสินค้าในแต่ละสาขา</label
                    >
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-items-center"
                  >
                    <div class="form-group text-center mr-2">
                      <label for="branchStockLoeiAdd"> อ.เมือง </label>
                      <input
                        class="form-control"
                        type="number"
                        id="branchStockLoeiAdd"
                        v-model="productAddData.input.branchStock.loei"
                      />
                    </div>
                    <div class="form-group text-center mr-2">
                      <label for="branchStockWangAdd"> อ.วังสะพุง </label>
                      <input
                        class="form-control"
                        type="number"
                        id="branchStockWangAdd"
                        v-model="productAddData.input.branchStock.wang"
                      />
                    </div>
                    <div class="form-group text-center mr-2">
                      <label for="branchStockCkAdd"> อ.เชียงคาน </label>
                      <input
                        class="form-control"
                        type="number"
                        id="branchStockCkAdd"
                        v-model="productAddData.input.branchStock.ck"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100 border-bottom pb-2">
                  <div class="w-100 d-flex justify-content-center">
                    <label class="font-weight-bold">โชว์สินค้าในสาขา</label>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center align-items-center"
                  >
                    <div class="form-check mr-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="loeiCheck"
                        v-model="productAddData.input.loei"
                      />
                      <label class="form-check-label" for="loeiCheck">
                        อ.เมือง
                      </label>
                    </div>
                    <div class="form-check mr-4">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="wangCheck"
                        v-model="productAddData.input.wang"
                      />
                      <label class="form-check-label" for="wangCheck">
                        อ.วังสะพุง
                      </label>
                    </div>
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="ckCheck"
                        v-model="productAddData.input.ck"
                      />
                      <label class="form-check-label" for="ckCheck">
                        อ.เชียงคาน
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="ssCheck"
                        v-model="productAddData.input.sub_sale"
                      />
                      <label class="form-check-label" for="ssCheck">
                        สินค้าลดราคา
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="newCheck"
                        v-model="productAddData.input.new"
                      />
                      <label class="form-check-label" for="newCheck">
                        สินค้าใหม่
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="saleCheck"
                        v-model="productAddData.input.sale"
                      />
                      <label class="form-check-label" for="saleCheck">
                        สินค้า SALE
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="hotCheck"
                        v-model="productAddData.input.hot"
                      />
                      <label class="form-check-label" for="hotCheck">
                        สินค้าขายดี
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="updateCheck"
                        v-model="productAddData.input.update"
                      />
                      <label class="form-check-label" for="updateCheck">
                        สินค้า UPDATE
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100">
                  <div class="col-12">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id="nicCheck"
                        v-model="productAddData.input.pairNic"
                        @change="productAddData.input.priceNic = 0"
                      />
                      <label class="form-check-label" for="nicCheck">
                        เพิ่มนิคได้
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row mt-3 w-100" v-if="productAddData.input.pairNic">
                  <div class="col-6">
                    <label class="form-control-label">ราคาเพิ่มนิค</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="productAddData.input.priceNic"
                    />
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="col-12">
                    <h5>เพิ่มตัวเลือกสินค้า</h5>
                  </div>
                </div>
                <div class="row w-100 mt-3">
                  <div class="col-4">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="ชื่อตัวเลือกสินค้า"
                      v-model="productAddData.stockInput.name"
                    />
                  </div>
                  <div class="col-4">
                    <select
                      class="form-control"
                      v-model="productAddData.stockInput.selectStock"
                    >
                      <option value="มีสินค้า">มีสินค้า</option>
                      <option value="สินค้าหมด">สินค้าหมด</option>
                    </select>
                  </div>
                  <div class="col-4">
                    <button
                      class="btn btn-base btn-warning w-100"
                      @click="onAddStock"
                    >
                      เพิ่ม
                    </button>
                  </div>
                </div>
                <div
                  v-if="this.productAddData.input.stock.length !== 0"
                  class="row w-100 mt-3"
                >
                  <div
                    class="row w-100 justify-content-center mb-2"
                    v-for="(item, index) in this.productAddData.input.stock"
                    :key="index"
                  >
                    <div class="col-4 text-center">
                      <div>{{ item.name }}</div>
                    </div>
                    <div class="col-3 text-center">
                      <div>
                        {{ item.instock === 1 ? "มีสินค้า" : "ไม่มีสินค้า" }}
                      </div>
                    </div>
                    <div class="col-3 text-danger text-center">
                      <i
                        class="fa fa-trash-alt"
                        @click="onDeleteStock(index)"
                        style="cursor: pointer"
                      ></i>
                    </div>
                  </div>
                </div>
                <div class="row w-100 mt-3">
                  <label class="form-control-label">รูปภาพหน้าปก</label>
                  <input
                    class="form-control"
                    ref="fileuploadaddproduct"
                    type="file"
                    @change="ImgfilesChange"
                  />
                </div>
                <div
                  class="row w-100 mt-3 justify-content-center"
                  v-if="productAddData.previewImage"
                >
                  <div>
                    <img
                      style="max-width: 100px; height: auto"
                      :src="productAddData.previewImage"
                      alt="previewImage"
                    />
                  </div>
                </div>
                <div class="row w-100 mt-3">
                  <label class="form-control-label"
                    >รูปภาพเพิ่มเติม <span class="text-xs">(ถ้ามี)</span></label
                  >
                  <input
                    class="form-control"
                    ref="fileuploadaddproductdes"
                    type="file"
                    @change="ImgfilesChangeDes"
                    multiple
                  />
                </div>
                <div
                  class="row w-100 mt-3 d-flex flex-wrap justify-content-center"
                  v-if="productAddData.previewImageDes.length != 0"
                >
                  <div
                    class="m-1"
                    v-for="(item, index) in productAddData.previewImageDes"
                    :key="index"
                  >
                    <img
                      style="max-width: 100px; height: auto"
                      :src="item"
                      alt="previewImage"
                    />
                  </div>
                </div>
                <div class="row w-100 mt-3 justify-content-center">
                  <button
                    class="btn btn-base btn-primary w-75"
                    @click="onAddProduct"
                  >
                    เพิ่มสินค้า
                  </button>
                </div>
              </div>
            </template>
          </card>
          <!-- ProductAddMenu -->
        </div>
      </div>
    </div>
    <div>
      <!-- Model CategoryCard Edit -->
      <modal v-model:show="categoryCard.category.editModal" size="sm">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">แก้ไขหมวดหมู่</h3>
            </div>
          </template>
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="edit_cate" class="form-control-label"
                  >ชื่อหมวดหมู่</label
                >
                <input
                  name="edit_cate"
                  id="edit_cate"
                  type="text"
                  class="form-control input-group-alternative"
                  v-model="categoryCard.category.inputEditCategory"
                />
              </div>
            </div>
          </div>
          <div class="text-right">
            <button
              type="submit"
              class="btn btn-primary"
              @click="onUpdateCategory"
            >
              ยืนยัน
            </button>
            <base-button
              type="danger"
              @click="categoryCard.category.editModal = false"
              >ปิด</base-button
            >
          </div>
        </card>
      </modal>
      <!-- Model ProductList Edit -->
      <modal v-model:show="productData.modal.show" size="lg">
        <card
          type="secondary"
          header-classes="bg-transparent border-0"
          class="border-0 mb-0"
        >
          <template v-slot:header>
            <div class="text-center">
              <h3 class="modal-title">แก้ไขสินค้า</h3>
            </div>
          </template>
          <div class="row w-100">
            <div class="col-6">
              <input
                type="text"
                class="form-control"
                placeholder="ชื่อสินค้า"
                v-model="productData.modal.input.name"
              />
            </div>
            <div class="col-6">
              <select
                class="form-control"
                v-model="productData.modal.input.category"
              >
                <option value="" disabled>--เลือกหมวดหมู่--</option>
                <option
                  v-for="(item, index) in categoryCard.category.tableData"
                  :key="index"
                  :value="item.name"
                >
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row w-100 mt-3">
            <div class="col-12">
              <textarea
                class="form-control"
                rows="3"
                placeholder="รายละเอียด"
                v-model="productData.modal.input.description"
              ></textarea>
            </div>
          </div>
          <div class="row w-100 mt-3">
            <div class="col-6">
              <label class="form-control-label">ราคา</label>
              <input
                type="number"
                class="form-control"
                v-model="productData.modal.input.price"
              />
            </div>
            <div class="col-6">
              <label class="form-control-label">ส่วนลด</label>
              <input
                type="number"
                class="form-control"
                v-model="productData.modal.input.discount"
              />
            </div>
          </div>
          <div class="row mt-3 w-100 border-bottom pb-2">
            <div class="w-100 d-flex justify-content-center">
              <label class="font-weight-bold">จำนวนสินค้าในแต่ละสาขา</label>
            </div>
            <div
              class="col-12 d-flex justify-content-center align-items-center"
            >
              <div class="form-group text-center mr-2">
                <label for="branchStockLoei"> อ.เมือง </label>
                <input
                  class="form-control"
                  type="number"
                  id="branchStockLoei"
                  v-model="productData.modal.input.branchStock.loei"
                />
              </div>
              <div class="form-group text-center mr-2">
                <label for="branchStockWang"> อ.วังสะพุง </label>
                <input
                  class="form-control"
                  type="number"
                  id="branchStockWang"
                  v-model="productData.modal.input.branchStock.wang"
                />
              </div>
              <div class="form-group text-center mr-2">
                <label for="branchStockCk"> อ.เชียงคาน </label>
                <input
                  class="form-control"
                  type="number"
                  id="branchStockCk"
                  v-model="productData.modal.input.branchStock.ck"
                />
              </div>
            </div>
            <div
              class="row d-flex justify-content-center align-items-center w-100"
            >
              <a
                :href="historyStockUrl + productData.fetchEditId"
                target="_blank"
                >ดูประวัติการแก้ไข</a
              >
            </div>
          </div>
          <div class="row mt-3 w-100 border-bottom pb-2">
            <div class="w-100 d-flex justify-content-center">
              <label class="font-weight-bold">โชว์สินค้าในสาขา</label>
            </div>
            <div
              class="col-12 d-flex justify-content-center align-items-center"
            >
              <div class="form-check mr-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="loeiCheckModal"
                  v-model="productData.modal.input.loei"
                />
                <label class="form-check-label" for="loeiCheckModal">
                  อ.เมือง
                </label>
              </div>
              <div class="form-check mr-4">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="wangCheckModal"
                  v-model="productData.modal.input.wang"
                />
                <label class="form-check-label" for="wangCheckModal">
                  อ.วังสะพุง
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="ckCheckModal"
                  v-model="productData.modal.input.ck"
                />
                <label class="form-check-label" for="ckCheckModal">
                  อ.เชียงคาน
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100">
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="ssCheckModal"
                  v-model="productData.modal.input.sub_sale"
                />
                <label class="form-check-label" for="ssCheckModal">
                  สินค้าลดราคา
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100">
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="newCheckModal"
                  v-model="productData.modal.input.new"
                />
                <label class="form-check-label" for="newCheckModal">
                  สินค้าใหม่
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100">
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="saleCheckModal"
                  v-model="productData.modal.input.sale"
                />
                <label class="form-check-label" for="saleCheckModal">
                  สินค้า SALE
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100">
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="hotCheckModal"
                  v-model="productData.modal.input.hot"
                />
                <label class="form-check-label" for="hotCheckModal">
                  สินค้าขายดี
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100">
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="updateCheckModal"
                  v-model="productData.modal.input.update"
                />
                <label class="form-check-label" for="updateCheckModal">
                  สินค้า UPDATE
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100">
            <div class="col-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="nicCheckModal"
                  v-model="productData.modal.input.pairNic"
                  @change="productData.modal.input.priceNic = 0"
                />
                <label class="form-check-label" for="nicCheckModal">
                  เพิ่มนิคได้
                </label>
              </div>
            </div>
          </div>
          <div class="row mt-3 w-100" v-if="productData.modal.input.pairNic">
            <div class="col-6">
              <label class="form-control-label">ราคาเพิ่มนิค</label>
              <input
                type="number"
                class="form-control"
                v-model="productData.modal.input.priceNic"
              />
            </div>
          </div>
          <div class="row w-100 mt-3 d-flex justify-content-center">
            <button
              class="btn btn-base btn-primary w-75"
              @click="onUpdateEditStock()"
            >
              บันทึก
            </button>
          </div>
          <div
            class="row w-100 mt-3"
            v-if="
              this.productData.modal.input.stock.length == 1 &&
              this.productData.modal.input.stock[0].options == 'ไม่มี'
            "
          >
            <div class="col-12">
              <label class="form-control-label">มีสินค้าในสาขา</label>
              <div class="row w-100 justify-content-center">
                <div class="form-check mr-2">
                  <!-- <input
                    class="form-control"
                    type="text"
                    v-model="productData.modal.input.stock[0].loei"
                  /> -->
                  <input
                    class="form-check-input"
                    type="checkbox"
                    style="cursor: pointer"
                    :checked="this.productData.modal.input.stock[0].loei == 1"
                    @click="
                      onEditStock(
                        this.productData.modal.input.stock[0].id,
                        'loei',
                        this.productData.modal.input.stock[0].loei == 1 ? 0 : 1
                      )
                    "
                  />
                  <label class="form-check-label"> อ.เมือง </label>
                </div>
                <div class="form-check mr-2">
                  <!-- <input
                    class="form-control"
                    type="text"
                    v-model="productData.modal.input.stock[0].wang"
                  /> -->
                  <input
                    class="form-check-input"
                    type="checkbox"
                    style="cursor: pointer"
                    :checked="this.productData.modal.input.stock[0].wang == 1"
                    @click="
                      onEditStock(
                        this.productData.modal.input.stock[0].id,
                        'wang',
                        this.productData.modal.input.stock[0].wang == 1 ? 0 : 1
                      )
                    "
                  />
                  <label class="form-check-label"> อ.วังสะพุง </label>
                </div>
                <div class="form-check mr-2">
                  <!-- <input
                    class="form-control"
                    type="text"
                    v-model="productData.modal.input.stock[0].ck"
                  /> -->
                  <input
                    class="form-check-input"
                    type="checkbox"
                    style="cursor: pointer"
                    :checked="this.productData.modal.input.stock[0].ck == 1"
                    @click="
                      onEditStock(
                        this.productData.modal.input.stock[0].id,
                        'ck',
                        this.productData.modal.input.stock[0].ck == 1 ? 0 : 1
                      )
                    "
                  />
                  <label class="form-check-label"> อ.เชียงคาน </label>
                </div>
              </div>
              <!-- <div class="row w-100 mt-3 d-flex justify-content-center">
                <button
                  class="btn btn-base btn-primary w-75"
                  @click="onEditStock()"
                >
                  บันทึก
                </button>
              </div> -->
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h5>เพิ่มตัวเลือกสินค้า</h5>
            </div>
          </div>
          <div class="row w-100 mt-3">
            <div class="col-4">
              <input
                type="text"
                class="form-control"
                placeholder="ชื่อตัวเลือกสินค้า"
                v-model="productData.modal.addStockInput.name"
              />
            </div>
            <div class="col-4">
              <select
                class="form-control"
                v-model="productData.modal.addStockInput.instock"
              >
                <option value="มีสินค้า">มีสินค้า</option>
                <option value="สินค้าหมด">สินค้าหมด</option>
              </select>
            </div>
            <div class="col-4">
              <button
                class="btn btn-base btn-warning w-100"
                @click="onAddEditStock()"
              >
                เพิ่ม
              </button>
            </div>
          </div>
          <div
            v-if="
              productData.modal.input.stock.length !== 0 &&
              productData.modal.input.stock[0].options !== 'ไม่มี'
            "
            class="row w-100 mt-3"
          >
            <div class="w-100 d-flex justify-content-center">
              <label class="font-weight-bold">มีสินค้าในสาขา</label>
            </div>
            <div
              class="row w-100 justify-content-center mb-2"
              v-for="(item, index) in productData.modal.input.stock"
              :key="index"
            >
              <div class="col-4">
                <div>{{ item.options }}</div>
              </div>
              <div class="col-6 text-center">
                <div class="d-flex">
                  <div class="form-check mr-3">
                    <!-- <input
                      class="form-control"
                      type="text"
                      v-model="productData.modal.input.stock[index].loei"
                    /> -->
                    <input
                      class="form-check-input"
                      type="checkbox"
                      style="cursor: pointer"
                      :checked="item.loei == 1"
                      @click="
                        onEditStock(item.id, 'loei', item.loei == 1 ? 0 : 1)
                      "
                    />
                    <label class="form-check-label"> อ.เมือง </label>
                  </div>
                  <div class="form-check mr-3">
                    <!-- <input
                      class="form-control"
                      type="text"
                      v-model="productData.modal.input.stock[index].wang"
                    /> -->
                    <input
                      class="form-check-input"
                      type="checkbox"
                      style="cursor: pointer"
                      :checked="item.wang == 1"
                      @click="
                        onEditStock(item.id, 'wang', item.wang == 1 ? 0 : 1)
                      "
                    />
                    <label class="form-check-label"> อ.วังสะพุง </label>
                  </div>
                  <div class="form-check">
                    <!-- <input
                      class="form-control"
                      type="text"
                      v-model="productData.modal.input.stock[index].ck"
                    /> -->
                    <input
                      class="form-check-input"
                      type="checkbox"
                      style="cursor: pointer"
                      :checked="item.ck == 1"
                      @click="onEditStock(item.id, 'ck', item.ck == 1 ? 0 : 1)"
                    />
                    <label class="form-check-label"> อ.เชียงคาน </label>
                  </div>
                  <!-- <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      style="cursor: pointer"
                      :checked="item.instock == 0"
                      @click="onEditStock(item.id, 0)"
                    />
                    <label class="form-check-label"> สินค้าหมด </label>
                  </div> -->
                </div>
              </div>
              <div class="col-2 text-danger text-center">
                <i
                  class="fa fa-trash-alt"
                  @click="onDeleteEditStock(item.id)"
                  style="cursor: pointer"
                ></i>
              </div>
            </div>
            <!-- <div class="row w-100 mt-3 d-flex justify-content-center">
              <button
                class="btn btn-base btn-primary w-75"
                @click="onEditStock()"
              >
                บันทึก
              </button>
            </div> -->
          </div>
          <div class="row w-100 mt-3">
            <label class="form-control-label">รูปภาพ</label>
            <input
              class="form-control"
              ref="fileuploadeditproduct"
              type="file"
              @change="ImgfilesEditProductChange"
            />
          </div>
          <div class="row w-100 mt-3 justify-content-center">
            <div>
              <img
                style="max-width: 100px; height: auto"
                class="rounded-lg"
                :src="imagePath + productData.modal.img"
                alt="previewImage"
              />
            </div>
          </div>
          <div class="row w-100 mt-3">
            <label class="form-control-label">รูปภาพเพิ่มเติม</label>
            <input
              class="form-control"
              ref="fileuploadeditproductdes"
              type="file"
              @change="ImgDesfilesEditProductChange"
              multiple
            />
          </div>
          <div v-if="productData.modal.input.length !== 0" class="row mt-3">
            <div
              class="row w-100 justify-content-center mb-2"
              v-for="(item, index) in productData.modal.imgDes"
              :key="index"
            >
              <div
                class="col-6 d-flex align-items-center justify-content-center"
              >
                <div>
                  <img
                    style="max-width: 100px; height: auto"
                    class="rounded-lg"
                    :src="imagePath + item.img"
                    alt="previewImage description"
                  />
                </div>
              </div>
              <div
                class="col-6 text-danger d-flex align-items-center justify-content-center"
              >
                <i
                  class="fa fa-trash-alt"
                  @click="onDeleteImgDes(item.img)"
                  style="cursor: pointer"
                ></i>
              </div>
            </div>
          </div>
          <div class="text-right mt-4">
            <base-button type="danger" @click="productData.modal.show = false"
              >ปิด</base-button
            >
          </div>
        </card>
      </modal>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
} from "element-plus";
import BasePagination from "@/components/BasePagination";
import axios from "../services/axios";
import { useToast } from "vue-toastification";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import modal from "../components/Modal.vue";

const toast = useToast();

export default {
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    modal,
  },
  data() {
    return {
      historyStockUrl: "https://admin.bbmshop.one/history-stock/",
      // historyStockUrl: "http://localhost:8080/history-stock/",
      imagePath: process.env.VUE_APP_IMAGE_PATH,
      liffPath: process.env.VUE_APP_LIFF_PATH,
      branch: "all",
      productData: {
        fetchEditId: null,
        modal: {
          show: false,
          input: {
            name: "",
            category: "",
            description: "",
            price: 0,
            discount: 0,
            stock: [],
            pairNic: false,
            priceNic: 0,
            ck: false,
            wang: false,
            loei: false,
            sub_sale: false,
            new: false,
            sale: false,
            hot: false,
            update: false,
            branchStock: {
              loei: 0,
              wang: 0,
              ck: 0,
            },
          },
          imgDes: [],
          addStockInput: {
            name: "",
            instock: "มีสินค้า",
          },
          img: undefined,
        },
        loading: false,
        pagination: {
          perPage: 10,
          currentPage: 1,
          perPageOptions: [5, 10, 25, 50],
          total: 0,
        },
        searchQuery: "",
        propsToSearch: ["name", "category", "discount", "price"],
        tableData: [],
        searchedData: [],
      },
      productAddData: {
        input: {
          name: "",
          category: "",
          stock: [],
          price: 0,
          discount: 0,
          description: "",
          img: undefined,
          imgDes: [],
          pairNic: false,
          priceNic: 0,
          ck: false,
          wang: false,
          loei: false,
          sub_sale: false,
          new: false,
          sale: false,
          hot: false,
          update: false,
          branchStock: {
            loei: 0,
            wang: 0,
            ck: 0,
          },
        },
        previewImage: "",
        previewImageDes: [],
        stockInput: {
          selectStock: "มีสินค้า",
          name: "",
        },
      },
      categoryCard: {
        inputCategory: "",
        category: {
          editName: "",
          inputEditCategory: "",
          editModal: false,
          loading: false,
          pagination: {
            perPage: 10,
            currentPage: 1,
            perPageOptions: [5, 10, 25, 50],
            total: 0,
          },
          searchQuery: "",
          propsToSearch: ["name"],
          tableData: [],
          searchedData: [],
        },
      },
    };
  },
  created() {
    this.fetchProduct();
    this.fetchCategory();
  },
  methods: {
    copyCategoryUrl(name) {
      let categoryEncoded = encodeURI(`?page=${name}`);
      let url = this.liffPath + categoryEncoded;
      navigator.clipboard.writeText(url);

      toast.success("คัดลอกลิงก์สำเร็จ");
    },
    // ProductList
    fetchProduct() {
      this.productData.loading = true;
      axios
        .get(`/admin/product?branch=${this.branch}`, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.productData.tableData = resp?.data?.data;
            this.productData.loading = false;
          }
        })
        .catch((err) => {
          this.productData.loading = false;
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeleteProduct(name) {
      swal
        .fire({
          title: `ยืนยันการลบสินค้า ?`,
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-warning",
            cancelButton: "btn btn-primary",
          },
          showCancelButton: true,
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          icon: "warning",
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            axios
              .post(
                "/admin/product/remove",
                {
                  name: name,
                },
                {
                  headers: {
                    Authorization: "Bearer " + localStorage.getItem("AUTH"),
                  },
                }
              )
              .then((resp) => {
                if (resp?.data?.status === "success") {
                  toast.success("ลบสินค้าสำเร็จ");
                  this.fetchProduct();
                }
              })
              .catch((err) => {
                if (err?.response) {
                  if (
                    err?.response?.status !== 401 &&
                    err?.response?.status !== 403
                  ) {
                    toast.error(
                      err?.response?.data?.message
                        ? err?.response?.data?.message
                        : "ไม่สามารถลบสินค้าได้"
                    );
                  } else {
                    this.$store.dispatch("logoutAuth");
                    localStorage.removeItem("AUTH");
                    this.$router.push("/login");
                  }
                }
              });
          }
        });
    },
    fetchEditProductData(id) {
      this.productData.fetchEditId = id;
      axios
        .get("/admin/product/" + id, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.productData.modal.input.name = resp?.data?.data[0].name;
            this.productData.modal.input.category =
              resp?.data?.data[0].category;
            this.productData.modal.input.description =
              resp?.data?.data[0].description;
            this.productData.modal.input.price = resp?.data?.data[0].price;
            this.productData.modal.input.discount =
              resp?.data?.data[0].discount;
            this.productData.modal.input.stock = resp?.data?.data[0].options;
            this.productData.modal.img = resp?.data?.data[0].img;
            this.productData.modal.input.pairNic = resp?.data?.data[0].pairNic;
            this.productData.modal.input.priceNic =
              resp?.data?.data[0].priceNic;
            this.productData.modal.imgDes = resp?.data?.images;
            this.productData.modal.input.ck =
              resp?.data?.data[0].ck === 1 ? true : false;
            this.productData.modal.input.wang =
              resp?.data?.data[0].wang === 1 ? true : false;
            this.productData.modal.input.loei =
              resp?.data?.data[0].loei === 1 ? true : false;
            this.productData.modal.input.sub_sale =
              resp?.data?.data[0].sub_sale == "sale" ? true : false;
            this.productData.modal.input.new =
              resp?.data?.data[0].new == 1 ? true : false;
            this.productData.modal.input.sale =
              resp?.data?.data[0].sale == 1 ? true : false;
            this.productData.modal.input.hot =
              resp?.data?.data[0].hot == 1 ? true : false;
            this.productData.modal.input.update =
              resp?.data?.data[0].p_update == 1 ? true : false;
            this.productData.modal.input.branchStock.loei =
              resp?.data?.data[0].loei_stock;
            this.productData.modal.input.branchStock.wang =
              resp?.data?.data[0].wang_stock;
            this.productData.modal.input.branchStock.ck =
              resp?.data?.data[0].ck_stock;

            this.productData.modal.show = true;
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลสินค้าได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    // onEditStock() {
    //   axios
    //     .post(
    //       "/admin/product/updatestock",
    //       {
    //         data: this.productData.modal.input.stock,
    //       },
    //       {
    //         headers: {
    //           Authorization: "Bearer " + localStorage.getItem("AUTH"),
    //         },
    //       }
    //     )
    //     .then((resp) => {
    //       if (resp?.data?.status === "success") {
    //         toast.success("แก้ไขสำเร็จ");
    //         this.fetchEditProductData(this.productData.fetchEditId);
    //       }
    //     })
    //     .catch((err) => {
    //       this.fetchEditProductData(this.productData.fetchEditId);
    //       if (err?.response) {
    //         if (
    //           err?.response?.status !== 401 &&
    //           err?.response?.status !== 403
    //         ) {
    //           toast.error(
    //             err?.response?.data?.message
    //               ? err?.response?.data?.message
    //               : "ไม่สามารถแก้ไขได้"
    //           );
    //         } else {
    //           this.$store.dispatch("logoutAuth");
    //           localStorage.removeItem("AUTH");
    //           this.$router.push("/login");
    //         }
    //       }
    //     });
    // },
    onEditStock(id, shop, instock) {
      axios
        .post(
          "/admin/product/updatestock",
          {
            stock: id,
            shop: shop,
            instock: instock,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("แก้ไขสำเร็จ");
            this.fetchEditProductData(this.productData.fetchEditId);
          }
        })
        .catch((err) => {
          this.fetchEditProductData(this.productData.fetchEditId);
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถแก้ไขได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeleteEditStock(id) {
      axios
        .post(
          "/admin/product/removestock",
          {
            stock: id,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบรายการสำเร็จ");
            this.fetchEditProductData(this.productData.fetchEditId);
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถลบรายการได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onDeleteImgDes(filename) {
      axios
        .post(
          "/admin/product/photos/remove",
          {
            filename: filename,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบรูปภาพสำเร็จ");
            this.fetchEditProductData(this.productData.fetchEditId);
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถลบรายการได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onAddEditStock() {
      if (this.productData.modal.addStockInput.name) {
        axios
          .post(
            "/admin/product/addstock",
            {
              product: this.productData.modal.input.name,
              options: this.productData.modal.addStockInput.name,
              instock:
                this.productData.modal.addStockInput.instock === "มีสินค้า"
                  ? 1
                  : 0,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("เพิ่มรายการสำเร็จ");
              this.productData.modal.addStockInput.name = "";
              this.productData.modal.addStockInput.instock = "มีสินค้า";
              this.fetchEditProductData(this.productData.fetchEditId);
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มรายการได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    onUpdateEditStock() {
      if (
        this.productData.modal.input.name &&
        this.productData.modal.input.category
      ) {
        axios
          .post(
            "/admin/product/update",
            {
              product: this.productData.fetchEditId,
              name: this.productData.modal.input.name,
              category: this.productData.modal.input.category,
              description: this.productData.modal.input.description,
              price: this.productData.modal.input.price,
              discount: this.productData.modal.input.discount,
              pairNic: this.productData.modal.input.pairNic,
              priceNic: this.productData.modal.input.priceNic,
              ck: this.productData.modal.input.ck,
              wang: this.productData.modal.input.wang,
              loei: this.productData.modal.input.loei,
              sale: this.productData.modal.input.sub_sale,
              newProduct: this.productData.modal.input.new,
              saleProduct: this.productData.modal.input.sale,
              hotProduct: this.productData.modal.input.hot,
              updateProduct: this.productData.modal.input.update,
              loeiStock: this.productData.modal.input.branchStock.loei,
              ckStock: this.productData.modal.input.branchStock.ck,
              wangStock: this.productData.modal.input.branchStock.wang,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("แก้ไขรายการสำเร็จ");
              this.fetchEditProductData(this.productData.fetchEditId);
              this.fetchProduct();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถแก้ไขรายการได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    ImgfilesEditProductChange(event) {
      if (event.target.files) {
        let fromData = new FormData();
        fromData.append("name", this.productData.modal.input.name);
        fromData.append("img", event.target.files[0]);
        axios
          .post("/admin/product/updateimage", fromData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("แก้ไขรูปภาพสำเร็จ");
              this.fetchEditProductData(this.productData.fetchEditId);
              this.fetchProduct();
              this.$refs.fileuploadeditproduct.value = null;
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถแก้ไขรูปภาพได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      }
    },
    ImgDesfilesEditProductChange(event) {
      if (event.target.files) {
        let FormDataImg = new FormData();

        FormDataImg.append("name", this.productData.modal.input.name);
        for (let i = 0; i < event.target.files.length; i++) {
          FormDataImg.append("photos", event.target.files[i]);
        }

        axios
          .post("/admin/product/photos/upload", FormDataImg, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("แก้ไขรูปภาพเพิ่มเติมสำเร็จ");
              this.fetchEditProductData(this.productData.fetchEditId);
              this.fetchProduct();
              this.$refs.fileuploadeditproductdes.value = null;
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถแก้ไขรูปภาพได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      }
    },
    //
    // ProductAddMenu
    //
    onAddProduct() {
      if (
        this.productAddData.input.name &&
        this.productAddData.input.category &&
        this.productAddData.input.price
      ) {
        let formData = new FormData();
        formData.append("name", this.productAddData.input.name);
        formData.append("category", this.productAddData.input.category);
        formData.append("desc", this.productAddData.input.description);
        formData.append("price", this.productAddData.input.price);
        formData.append("discount", this.productAddData.input.discount);
        formData.append(
          "stock",
          JSON.stringify(this.productAddData.input.stock)
        );
        formData.append("pairNic", this.productAddData.input.pairNic);
        formData.append("priceNic", this.productAddData.input.priceNic);
        formData.append("img", this.productAddData.input.img);
        formData.append("ck", this.productAddData.input.ck);
        formData.append("wang", this.productAddData.input.wang);
        formData.append("loei", this.productAddData.input.loei);
        formData.append("sale", this.productAddData.input.sub_sale);
        formData.append("newProduct", this.productAddData.input.new);
        formData.append("saleProduct", this.productAddData.input.sale);
        formData.append("hotProduct", this.productAddData.input.hot);
        formData.append("updateProduct", this.productAddData.input.update);
        formData.append(
          "loeiStock",
          this.productAddData.input.branchStock.loei
        );
        formData.append(
          "wangStock",
          this.productAddData.input.branchStock.wang
        );
        formData.append("ckStock", this.productAddData.input.branchStock.ck);

        axios
          .post("/admin/product/add", formData, {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          })
          .then((resp) => {
            if (resp?.data?.status === "success") {
              this.productAddData.input.name = "";
              this.productAddData.input.category = "";
              this.productAddData.input.description = "";
              this.productAddData.input.price = 0;
              this.productAddData.input.discount = 0;
              this.productAddData.input.stock = [];
              this.productAddData.input.pairNic = false;
              this.productAddData.input.priceNic = 0;
              this.productAddData.input.img = undefined;
              this.productAddData.input.ck = false;
              this.productAddData.input.wang = false;
              this.productAddData.input.loei = false;
              this.productAddData.input.sub_sale = false;
              this.productAddData.input.new = false;
              this.productAddData.input.sale = false;
              this.productAddData.input.hot = false;
              this.productAddData.input.update = false;
              this.productAddData.input.branchStock.loei = 0;
              this.productAddData.input.branchStock.wang = 0;
              this.productAddData.input.branchStock.ck = 0;
              this.productAddData.previewImage = "";
              this.$refs.fileuploadaddproduct.value = null;
              this.fetchProduct();
              toast.success("เพิ่มสินค้าสำเร็จ");
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มข้อมูลได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });

        if (this.productAddData.input.imgDes.length != 0) {
          let FormDataImg = new FormData();
          FormDataImg.append("name", this.productAddData.input.name);
          for (let i = 0; i < this.productAddData.input.imgDes.length; i++) {
            FormDataImg.append("photos", this.productAddData.input.imgDes[i]);
          }

          axios
            .post("/admin/product/photos/upload", FormDataImg, {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            })
            .then(() => {
              this.$refs.fileuploadaddproductdes.value = null;
              this.productAddData.input.imgDes = [];
              this.productAddData.previewImageDes = [];
            })
            .catch((err) => {
              console.log(err.response);
            });
        }
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    ImgfilesChange(event) {
      if (event.target.files) {
        this.productAddData.input.img = event.target.files[0];
        this.productAddData.previewImage = URL.createObjectURL(
          this.productAddData.input.img
        );
      }
    },
    ImgfilesChangeDes(event) {
      this.productAddData.previewImageDes = [];
      this.productAddData.input.imgDes = [];
      if (event.target.files) {
        for (let i = 0; i < event.target.files.length; i++) {
          this.productAddData.input.imgDes.push(event.target.files[i]);
          this.productAddData.previewImageDes.push(
            URL.createObjectURL(event.target.files[i])
          );
        }
      }
    },
    onAddStock() {
      if (
        this.productAddData.stockInput.name &&
        this.productAddData.stockInput.selectStock
      ) {
        this.productAddData.input.stock.push({
          name: this.productAddData.stockInput.name,
          instock:
            this.productAddData.stockInput.selectStock === "มีสินค้า" ? 1 : 0,
        });
        this.productAddData.stockInput.name = "";
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    onDeleteStock(key) {
      this.productAddData.input.stock = this.productAddData.input.stock.filter(
        (item, index) => {
          if (index != key) {
            return item;
          }
        }
      );
    },
    // ProductAddMenu
    // CategoryCard
    onUpdateCategory() {
      if (
        this.categoryCard.category.editName &&
        this.categoryCard.category.inputEditCategory
      ) {
        axios
          .post(
            "/admin/category/edit",
            {
              category: this.categoryCard.category.editName,
              name: this.categoryCard.category.inputEditCategory,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              toast.success("แก้ไขหมวดหมู่สำเร็จ");
              (this.categoryCard.category.inputEditCategory = ""),
                (this.categoryCard.category.editName = ""),
                (this.categoryCard.category.editModal = false);
              this.fetchCategory();
              this.fetchProduct();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถแก้ไขหมวดหมู่ได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    onDeleteCategory(name) {
      axios
        .post(
          "/admin/category/remove",
          {
            category: name,
          },
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("AUTH"),
            },
          }
        )
        .then((resp) => {
          if (resp?.data?.status === "success") {
            toast.success("ลบหมวดหมู่สำเร็จ");
            this.fetchCategory();
            this.fetchProduct();
          }
        })
        .catch((err) => {
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถลบหมวดหมู่ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    onSubmitAddCategory() {
      if (this.categoryCard.inputCategory) {
        axios
          .post(
            "/admin/category/add",
            {
              category: this.categoryCard.inputCategory,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("AUTH"),
              },
            }
          )
          .then((resp) => {
            if (resp?.data?.status === "success") {
              this.categoryCard.inputCategory = "";
              toast.success("เพิ่มหมวดหมู่สำเร็จ");
              this.fetchCategory();
            }
          })
          .catch((err) => {
            if (err?.response) {
              if (
                err?.response?.status !== 401 &&
                err?.response?.status !== 403
              ) {
                toast.error(
                  err?.response?.data?.message
                    ? err?.response?.data?.message
                    : "ไม่สามารถเพิ่มข้อมูลได้"
                );
              } else {
                this.$store.dispatch("logoutAuth");
                localStorage.removeItem("AUTH");
                this.$router.push("/login");
              }
            }
          });
      } else {
        toast.error("กรุณากรอกข้อมูล");
      }
    },
    fetchCategory() {
      this.categoryCard.category.loading = true;
      axios
        .get("/admin/category", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("AUTH"),
          },
        })
        .then((resp) => {
          if (resp?.data?.status === "success") {
            this.categoryCard.category.tableData = resp?.data?.data;
            this.categoryCard.category.loading = false;
          }
        })
        .catch((err) => {
          this.categoryCard.category.loading = false;
          if (err?.response) {
            if (
              err?.response?.status !== 401 &&
              err?.response?.status !== 403
            ) {
              toast.error(
                err?.response?.data?.message
                  ? err?.response?.data?.message
                  : "ไม่สามารถโหลดข้อมูลหมวดหมู่ได้"
              );
            } else {
              this.$store.dispatch("logoutAuth");
              localStorage.removeItem("AUTH");
              this.$router.push("/login");
            }
          }
        });
    },
    // CategoryCard
  },
  computed: {
    ...mapGetters(["IsLoggedIn"]),

    // ProductList
    pagedDataProduct() {
      return this.productData.tableData.slice(this.fromProduct, this.toProduct);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataProduct}
     */
    queriedDataProduct() {
      if (!this.productData.searchQuery) {
        return this.pagedDataProduct;
      }
      let result = this.productData.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.productData.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(this.productData.searchQuery.toLowerCase())
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromProduct, this.toProduct);
    },
    toProduct() {
      let highBound = this.fromProduct + this.productData.pagination.perPage;
      if (this.totalProduct < highBound) {
        highBound = this.totalProduct;
      }
      return highBound;
    },
    fromProduct() {
      return (
        this.productData.pagination.perPage *
        (this.productData.pagination.currentPage - 1)
      );
    },
    totalProduct() {
      return this.productData.searchedData.length > 0
        ? this.productData.searchedData.length
        : this.productData.tableData.length;
    },

    // CategoryCard
    pagedDataCategory() {
      return this.categoryCard.category.tableData.slice(
        this.fromCategory,
        this.toCategory
      );
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedDataCategory}
     */
    queriedDataCategory() {
      if (!this.categoryCard.category.searchQuery) {
        return this.pagedDataCategory;
      }
      let result = this.categoryCard.category.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.categoryCard.category.propsToSearch) {
          let rowValue = row[key].toString().toLowerCase();
          if (
            rowValue.includes &&
            rowValue.includes(
              this.categoryCard.category.searchQuery.toLocaleLowerCase()
            )
          ) {
            isIncluded = true;
          }
        }
        return isIncluded;
      });

      return result.slice(this.fromCategory, this.toCategory);
    },
    toCategory() {
      let highBound =
        this.fromCategory + this.categoryCard.category.pagination.perPage;
      if (this.totalCategory < highBound) {
        highBound = this.totalCategory;
      }
      return highBound;
    },
    fromCategory() {
      return (
        this.categoryCard.category.pagination.perPage *
        (this.categoryCard.category.pagination.currentPage - 1)
      );
    },
    totalCategory() {
      return this.categoryCard.category.searchedData.length > 0
        ? this.categoryCard.category.searchedData.length
        : this.categoryCard.category.tableData.length;
    },
  },
};
</script>

<style>
.el-table .el-table__header-wrapper thead th .cell {
  justify-content: center;
}
</style>
